@media screen and (orientation: landscape) and (min-width: 500px) {
    #landscapeMode {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        color: #FFFFFF;
        text-align: center;
    }
    #landscapeMode p {
        width: 80vw;
        font-size: 2em;
        font-weight: 300;
    }
    #portraitMode {
        display: none;
    }
}

@media screen and (orientation: portrait) {
    #landscapeMode {
        display: none;
    }
    #portraitMode {
        display: block;
    }
}

@media screen and (max-height: 450px) {
    #footer {
        display: none;
    }
}

* {
    font-family: 'Roboto', sans-serif;
}
body {
    margin: 0;
    background-image: linear-gradient(-45deg, #0D344F, #1F6487);
}
a {
    text-decoration: none;
}
p {
    margin: 0px;
}

.ptr--ptr, .ptr--ptr .ptr--text, .ptr--ptr .ptr--icon {
    color: #FFFFFF;
}
